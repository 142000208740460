import {
    Swiper,
    Grid,
    Navigation,
    Pagination,
    Scrollbar,
    EffectCoverflow
} from 'swiper';




Swiper.use([Navigation,Grid ,Pagination, Scrollbar, EffectCoverflow]);

export function horscrollsw(sliders = ".swiper-scroller") {


    var scrollersBuild = (function() {

        let elements = {
            sl: sliders
        };

        var build = function(selector) {

            var arr = document.querySelectorAll(selector);
            arr.forEach(function(el, index) {
                const settings = el.dataset.settings ||
                    JSON.stringify({ slidesDesktop: 3, slidesTablet: 3, slidesMobile: 2 });
                const dataArr = JSON.parse(settings);
                let filters = el.dataset.filterby;
                el.classList.add('swiper-slider-' + index);
                var dragSize = 50;
                var freeMode = false;
                var loop = false;
                var slidesDesktop = dataArr.slidesDesktop || 3;
                var slidesTablet = dataArr.slidesTablet || 3;
                var slidesMobile = dataArr.slidesMobile || 2;
                var spaceBetween = 30;
                var watchOverflow = true;

                var swiper
                var createslider = function() {
                    swiper = new Swiper('.swiper-slider-' + index, {

                        modules: [Navigation, Pagination, Scrollbar, EffectCoverflow],
                        direction: 'horizontal',
                        loop: loop,
                        freeMode: freeMode,
                        watchOverflow: watchOverflow,
                        spaceBetween: spaceBetween,
                        breakpoints: {
                            1200: {
                                slidesPerView: slidesDesktop
                            },
                            992: {
                                slidesPerView: slidesTablet
                            },
                            480: {
                                slidesPerView: slidesMobile
                            }
                        },
                        on: {
                            afterInit: function(sw) {

                            },
                            slidesLengthChange: function(sw) {

                            },
                            update: function(sw) {

                            },
                        },
                        navigation: {
                            nextEl: '.swiper-slider-' + index + ' .swiper-button-next',
                            prevEl: '.swiper-slider-' + index + ' .swiper-button-prev'
                        },
                        scrollbar: {
                            el: '.swiper-slider-' + index + ' .swiper-scrollbar',
                            draggable: true,
                            hide: false
                        }
                    });




                }

                createslider();

            })
        };


        var buildall = function() {
            build(elements.sl);
        };

        return {
            init: function() {
                buildall();
            }
        };
    })();

    scrollersBuild.init();

}




export function gridscroll(sliders = ".swiper-grider") {


    var gridBuild = (function() {
        //alert('nook');
        let elements = {
            sl: sliders
        };

        var build = function(selector) {

            var arr = document.querySelectorAll(selector);
            arr.forEach(function(el, index) {
                const settings = el.dataset.settings ||
                    JSON.stringify({ slidesDesktop: 2, slidesTablet: 2, slidesMobile: 2 });
                const dataArr = JSON.parse(settings);
                let filters = el.dataset.filterby;
                el.classList.add('swiper-grider-' + index);
                var dragSize = 50;
                var freeMode = false;
                var loop = false;
                var slidesDesktop = dataArr.slidesDesktop || 6;
                var slidesTablet = dataArr.slidesTablet || 6;
                var slidesMobile = dataArr.slidesMobile || 6;
                var spaceBetween = 30;
                var watchOverflow = true;

                var swiper
                var createslider = function() {
                    swiper = new Swiper('.swiper-grider-' + index, {
                        modules: [Grid, Pagination],
                        grid: {
                          column:2,
                          rows: 3,
                          fill: 'column',
                        },
                        pagination: {
                            el: '.swiper-pagination',
                            type: 'bullets',
                          },
                        direction: 'horizontal',
                        loop: loop,
                        freeMode: freeMode,
                        watchOverflow: watchOverflow,
                        spaceBetween: spaceBetween,
                        breakpoints: {
                            1200: {
                                slidesPerView: slidesDesktop
                            },
                            992: {
                                slidesPerView: slidesTablet
                            },
                            480: {
                                slidesPerView: slidesMobile
                            }
                        },
                        on: {
                            afterInit: function(sw) {

                            },
                            slidesLengthChange: function(sw) {

                            },
                            update: function(sw) {

                            },
                        }
                    });




                }

                createslider();

            })
        };


        var buildall = function() {
            build(elements.sl);
        };

        return {
            init: function() {
                buildall();
            }
        };
    })();

    gridBuild.init();

}

export function tabScroll(sliders = ".tab-nav") {


    var tabBuild = (function() {
        //alert('nook');
        let elements = {
            sl: sliders
        };

        var build = function(selector) {

            var arr = document.querySelectorAll(selector);
            arr.forEach(function(el, index) {
                const tabl = el;
                

                var swiper
                var createslider = function() {
                    swiper = new Swiper(tabl, {
                        modules: [Navigation, Pagination, Scrollbar, EffectCoverflow],
                        direction: 'horizontal',
                        slidesPerView: "auto",
                        freeMode: false
                    });

                }

                createslider();

            })
        };

        var buildall = function() {
            build(elements.sl);
        };

        return {
            init: function() {
                buildall();
            }
        };
    })();

    tabBuild.init();

}

