
import Shuffle from 'shufflejs';
import AOS from 'aos';
import Swiper, { Navigation, Pagination, Autoplay, Controller, EffectFade } from 'swiper';

Swiper.use([Navigation, Pagination, Autoplay, Controller, EffectFade]);

function App() {
    "use strict";

    /**
     * Easy isotope news
     */
    let shufCont = document.getElementById('isonumb')
    if (shufCont) {

        const shuffleInstance = new Shuffle(shufCont, {
            itemSelector: '.itemNumb',
            sizer: ".grid-sizer",
            buffer: 1,
            isCentered: true,
        })
    }


    /**
     * Easy selector helper function
     */
    const select = (el, all = false) => {
        el = el.trim()
        if (all) {
            return [...document.querySelectorAll(el)]
        } else {
            return document.querySelector(el)
        }
    }

    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
        if (all) {
            select(el, all).forEach(e => e.addEventListener(type, listener))
        } else {
            select(el, all).addEventListener(type, listener)
        }
    }

    /**
     * Easy on scroll event listener
     */
    const onscroll = (el, listener) => {
        el.addEventListener('scroll', listener)
    }

    /**
     * Navbar links active state on scroll
     */
    let navbarlinks = select('#navbar .scrollto', true)
    const navbarlinksActive = () => {
        let position = window.scrollY + 200
        navbarlinks.forEach(navbarlink => {
            if (!navbarlink.hash) return
            let section = select(navbarlink.hash)
            if (!section) return
            if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
                navbarlink.classList.add('active')
            } else {
                navbarlink.classList.remove('active')
            }
        })
    }
    window.addEventListener('load', navbarlinksActive)
    onscroll(document, navbarlinksActive)

    /**
     * Scrolls to an element with header offset
     */
    const scrollto = (el) => {
        let header = select('#header')
        let offset = header.offsetHeight

        if (!header.classList.contains('header-scrolled')) {
            offset -= 10
        }

        let elementPos = select(el).offsetTop
        window.scrollTo({
            top: elementPos - offset,
            behavior: 'smooth'
        })
    }

    /**
     * Toggle .header-scrolled class to #header when page is scrolled
     */
    let selectHeader = select('#header')
    if (selectHeader) {
        const headerScrolled = () => {
            if (window.scrollY > 100) {
                selectHeader.classList.add('header-scrolled')
            } else {
                selectHeader.classList.remove('header-scrolled')
            }
        }
        window.addEventListener('load', headerScrolled)
        onscroll(document, headerScrolled)
    }

    /**
     * Back to top button
     */
    let backtotop = select('.back-to-top')
    if (backtotop) {
        const toggleBacktotop = () => {
            if (window.scrollY > 100) {
                backtotop.classList.add('active')
            } else {
                backtotop.classList.remove('active')
            }
        }
        window.addEventListener('load', toggleBacktotop)
        onscroll(document, toggleBacktotop)
    }

    /**
     * Mobile nav dropdowns activate
     */
    //on('click', '.navbar .dropdown > a', function(e) {
    //    if (select('#navbar').classList.contains('navbar-mobile')) {
    //        e.preventDefault()
    //        this.nextElementSibling.classList.toggle('dropdown-active')
    //    }
    //}, true)

    /**
     * Scrool with ofset on links with a class name .scrollto
     */
    on('click', '.scrollto', function(e) {
        if (select(this.hash)) {
            e.preventDefault()

            let navbar = select('#navbar')
            if (navbar.classList.contains('navbar-mobile')) {
                navbar.classList.remove('navbar-mobile')
                let navbarToggle = select('.mobile-nav-toggle')
                navbarToggle.classList.toggle('bi-list')
                navbarToggle.classList.toggle('bi-x')
            }
            scrollto(this.hash)
        }
    }, true)

    /**
     * Scroll with ofset on page load with hash links in the url
     */
    window.addEventListener('load', () => {
        if (window.location.hash) {
            if (select(window.location.hash)) {
                scrollto(window.location.hash)
            }
        }
    });




    /**
     * Clients Slider
     */


     var swiperProdImage = new Swiper('.headerban', {
        speed: 600,
        loop: true,
        perPage: 1,
        effect: 'fade',
        centeredSlides: true,
        fadeEffect: {
          crossFade: true
        },
        //autoplay: {
        //    delay: 2000,
        //    disableOnInteraction: false
        //},
        slidesPerView: 1,
    });

    var swiperClients = new Swiper(".swiper-clients", {
      slidesPerView: 4,
      breakpoints: {
          1200: {
              slidesPerView: 4
          },
          992: {
              slidesPerView: 3
          },
          480: {
              slidesPerView: 2
          }
      },
      spaceBetween: 30,
      loop: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });

    var swiperThumbs = new Swiper('.headercont', {
        speed: 600,
        loop: true,
        perPage: 1,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        centeredSlides: true,
        slidesPerView: 1,
        navigation: {
          nextEl: '.hero-nav.next',
          prevEl: '.hero-nav.prev',
        },
    });

    swiperProdImage.controller.control = swiperThumbs;
    swiperThumbs.controller.control = swiperProdImage;
    swiperClients.controller.control = swiperClients;

    /*/

    /*/

    /**
     * Animation on scroll
     */
    function aos_init() {
        AOS.init({
            duration: 1000,
            easing: "ease-in-out",
            once: true,
            mirror: false
        });
    }
    window.addEventListener('load', () => {
        aos_init();
    });




};


export default App;
