/*
 * Main frontend Javascript entrypoint.
 *
 * Note than jQuery is not required by Bootstrap and just loaded for convenience for
 * developers which can't or don't want to develop in vanilla Javascript.
 */

//
// Make jQuery object usable inside modules
//

//import $ from "jquery";

//
// Make jQuery object usable from templates
//

//window.jQuery = $;
//window.$ = $;

//** WebSite Application //

import App from './modules/App';

import { addFiltersTo } from "./modules/tabfilter";
import { horscrollsw,gridscroll,tabScroll } from "./modules/horscroller";
import { navToMob } from './modules/nav';
import { addIncNumbs } from './modules/increm'

const run = () => {
    horscrollsw();
    addFiltersTo();
    gridscroll();
    navToMob();
    tabScroll();
    addIncNumbs();
}

document.addEventListener('DOMContentLoaded', function () {
    // splide JS
    run();
})

App();

$(document).ready(function(){
    $("#HomeModal").modal('show');
});
