export function addFiltersTo(group = ".filtered-group") {

    let elements = {
        bag: group,
        filters: 'data-filterby'
    };

    var bags = document.querySelectorAll(elements.bag);

    bags.forEach(function (el, i) {
        let filtID = el.getAttribute(elements.filters);
        let filts = document.getElementById(filtID);
        let sAll = el.querySelectorAll(".filtered");
        let navits = filts.querySelectorAll('.nav-link');

        let navstart = navits[0];
        navstart.classList.add("active");
        let filtstart = navstart.dataset.filter;
        slstart = el.querySelectorAll("." + filtstart);
        let slshowstart;
        if (filtstart == "all") {
            slshowstart = sAll
        } else {
            slshowstart = el.querySelectorAll("." + filtstart);
        };
        for (const sl of sAll) {
            sl.classList.add("d-none");
            sl.classList.remove("d-block");
        }
        for (const sl of slshowstart) {
            sl.classList.add("d-block");
            sl.classList.remove("d-none");
        }
        const cont = bags[i];
        navits.forEach(function (tab) {
            tab.onmouseover= function () {
                tab.querySelectorAll('.icosvg').forEach( svg => {
                    svg.getSVGDocument().getElementById("bord").setAttribute("style", "fill:#07B3C2")
                    svg.getSVGDocument().getElementById("back").setAttribute("style", "fill:#ffffff")

                })
            }

            tab.onmouseout= function () {
                tab.querySelectorAll('.icosvg').forEach( svg => {
                    svg.getSVGDocument().getElementById("bord").setAttribute("style", "fill:#000000")
                    svg.getSVGDocument().getElementById("back").setAttribute("style", "fill:##C4C4C4")

                })
            }
            tab.onclick = function () {
                navits.forEach(it => {
                    it.classList.remove("active");
                });
                tab.classList.add("active");
                let filtme = tab.dataset.filter;
                let slshow;
                if (filtme == "all") {
                    slshow = sAll
                } else {
                    slshow = el.querySelectorAll("." + filtme);
                };
                for (const sl of sAll) {
                    sl.classList.add("d-none");
                    sl.classList.remove("d-block");
                }
                for (const sl of slshow) {
                    sl.classList.add("d-block");
                    sl.classList.remove("d-none");
                }
                return false;

            }

        })
    })

}