export function navToMob(topnav = ".navbar") {

    if (window.innerWidth < 992) {
        // close all inner dropdowns when parent is closed
        document.querySelectorAll('.navbar .dropdown').forEach(function(everydropdown){
          
          everydropdown.addEventListener('hidden.bs.dropdown', function () {
            // after dropdown is hidden, then find all submenus

          console.log(this);
      
              this.querySelectorAll('.submenu').forEach(function(everysubmenu){
                // hide every submenu as well 
                everysubmenu.style.display = 
                (everysubmenu.style.display == 'block') ?
                  'none' :
                  'block'
              });
          })
          
        });
      
          /*
        document.querySelectorAll('.dropdown-menu a').forEach(function(element){
          element.addEventListener('click', function (e) {
              let nextEl = this.nextElementSibling;
              if(nextEl && nextEl.classList.contains('submenu')) {	
                // prevent opening link if link needs to open dropdown
                e.preventDefault();
                if(nextEl.style.display == 'block'){
                  nextEl.style.display = 'none';
                } else {
                  nextEl.style.display = 'block';
                }
      
              }
          });
        })
          */

    
      }

      onresize = (event) => {
        if (window.innerWidth > 991) {
          document.body.classList.remove('overflow-hidden');
          document.getElementById('main_nav').classList.remove('show');
        }
      };
      
}