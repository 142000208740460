


function increment(elem, finalVal) {
    var currVal = parseInt(document.getElementById(elem).innerHTML, 10);
    if (currVal < finalVal) {
        currVal += Math.floor(((finalVal)/200)+1);
        document.getElementById(elem).innerHTML = currVal ;
        //alert(times)
        setTimeout(function() {
            increment(elem, finalVal);
        }, 1000/finalVal)
    } else {
        document.getElementById(elem).innerHTML = currVal = finalVal
    }
};


export function addIncNumbs () {
    const SECTIONS = [...document.querySelectorAll('[data-total]')]
    let idCounter = 0;
    let debounceTimeout = null;
    const io_options = {
        root: document.querySelector('#main'),
        rootMargin: '250px',
        threshold:  [0.75, 1]
        };



    function io_callback (entries) {
        clearTimeout(debounceTimeout);
        entries.forEach(entry => {
            entry.target.classList.toggle('active', entry.isIntersecting)
            let idit = entry.target.id
            let tot = entry.target.dataset.total
            console.log(entries.some)
            console.log(entry.intersectionRatio)
            debounceTimeout = setTimeout(function(){increment(idit,tot) }, 100)
        });
        
    }

    

    SECTIONS.forEach((section) => {
        section.dataset.scrollid=idCounter++;
        let io_observer = new IntersectionObserver(entries => {
      
            // Available data when an intersection happens
            console.log(entries);
      
            // Element enters the viewport
            if(entries[0].intersectionRatio !== 0) {
                let idit = entries[0].target.id
                let tot = entries[0].target.dataset.total
                let times = Math.floor(tot)+1
                debounceTimeout = setTimeout(function(){increment(idit,tot,times) }, 100)
    
      
            // Element leaves the viewport 
            } else {
                entries[0].target.innerHTML = "0" ;
            }
      
            
          });
        setTimeout(() => {
            io_observer.observe(section)
        }, 500)
    })

}

